.menuProfil {
    position: relative;
    display: flex;
    align-items: center; /* Centre verticalement l'image */
  }

  .menuProfil img {
    border-radius: 50%; /* Optionnel : arrondit l'image */
    margin-right: 0.5rem; /* Espace entre l'image et le texte */
    vertical-align: middle; /* Assure que l'image reste centrée */
  }
  
  .menuProfil a {
    display: flex;
    align-items: center; /* Centre également l'icône ou le texte */
  }
  
  /* .menuProfil:hover {
    display: block;
  } */

  .menuProfil:hover .submenu {
    display: block !important;
  }
  
  .submenu {
    display: none !important;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    list-style: none;
    min-width: 250px; 
    z-index: 1000;
  }
  
  .submenu li {
    width: 100%; /* Assurez-vous que les éléments li prennent toute la largeur du sous-menu */
  }
  
  .submenu .CustomNavLinkSmall {
    margin: 0;
    width: 100%;
  }
  
  .menuProfil a {
    display: flex;
    align-items: center;
  }
  